.footer{
  width: 100vw;
  position: relative;
  padding: 3em 0 1em 0; 
  background-color: #000000;  
}
.container-footer{
  width: 100%;
  max-width: 85%;
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.container-footer-upper-part{
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1.3fr 1.5fr 1fr;
  gap: 1.5em;
}
.container-footer .divide-line{
  background-color: #fff;
  width: 100%;
  height: 1px;
  margin-top: 20px;
}
.footer-first-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* .footer-first-column p{
   color: #fff;
   font-size: 15px;
   font-weight: 300;
   margin-top: 30px;
} */
.container-footer h5{
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 20px;
}
.footer-second-column .list-group{
  background: transparent;
  border: 0;
}
.footer-second-column .list-group .list-group-item{
  background: none;
  border: 0;
  line-height: 2.5;
  padding: 0;
}
.footer-third-column .list-group .list-group-item{
  background: none;
  border: 0;
  line-height: 2.5;
  padding: 0;
}
.footer-second-column .list-group .list-group-item .quick-link-text{
  color: #fff;
  font-size: 17px;
  font-weight: 300;
}
.footer-third-column .list-group .list-group-item .quick-link-text{
  color: #fff;
  font-size: 17px;
  font-weight: 300;
}
.footer-second-column .list-group .list-group-item .quick-link-text:hover{
  font-weight: 500;
}
.footer-third-column .list-group .list-group-item .quick-link-text:hover{
  font-weight: 500;
}
.footer-fourth-column .social-links{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap:1em;
}
.footer-fourth-column .social-links .icon-link{
  color: #ffff;
  font-size: 25px;
  transition: 0.5s ease all;
}
.footer-fourth-column .social-links .icon-link svg{
  transition: 0.5s ease;
  transform: scale(1);
}
.footer-fourth-column .social-links .icon-link:hover svg{
  transform: scale(1.5);
}
.container-footer-last{
  width: 100%;
  max-width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1em;
}
.first-column-last{
  width: 50%;
}
.last-list-group{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2em;
  list-style: none;
}
.last-list-group .last-list-group-item a{
  color: #fff;
  font-size: 15px;
  font-weight: 300;
}
.last-list-group .last-list-group-item a:hover{
  text-decoration: underline;
  font-weight: 500;
}
.second-column-last{
  width: 50%;
  text-align: right;
}
.copyright-column p{
  font-size: 15px;
  color: #fff;
  font-weight: 300;
}
.copyright-column p a{
  color: #fff;
}
@media (min-width: 1500px) {
  .container-footer, .container-footer-last{
      width: 1300px;
  }
}
@media (max-width: 980px) {
  .container-footer-upper-part{
    grid-template-columns: 2fr 1.5fr 1.5fr 1.5fr;
  }
  .container-footer h5{
   font-size: 20px;
  }
}
@media (max-width: 910px) {
  .container-footer-upper-part{
    grid-template-columns: 2fr 2fr 2fr 1.5fr;
  }
}
@media (max-width: 866px) {
  .container-footer-upper-part{
    grid-template-columns: 2fr 2fr;
  }
  .container-footer .divide-line{
    margin-top: 30px;
  }
  .footer-third-column, .footer-fourth-column{
    margin-top: 30px;
  }
}
@media (max-width: 768px){
  .container-footer-last{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .first-column-last{
    width: 100%;
  }
  .second-column-last{
    width: 100%;
  }
  .last-list-group{
    justify-content: center;
    align-items: center;
  }
  .second-column-last{
    text-align: center;
  }
}
@media (max-width: 510px){
  .container-footer-upper-part{
    display: block;
  }
  .footer-second-column{
    margin-top: 40px;
  }
  .footer-second-column .list-group .list-group-item .quick-link-text{
    font-size: 15px;
  }
  .footer-third-column .list-group .list-group-item .quick-link-text{
    font-size: 15px;
  }
  .last-list-group .last-list-group-item a{
    font-size: 13px;
  }
  .copyright-column p{
    font-size: 13px;
  }
 
}

