.coursesSection{
   width: 100vw;
   position: relative; 
   margin-top: -5em;
   padding: 1em 0 3em 0;
}
.courses-container{
    width: 100%;
    max-width: 85%;
    position: relative;
    margin-bottom: 3em;
} 
.course-container-heading{
    margin-bottom: 3em;
}
.course-container-heading h1{
    color: #000;
    font-size: 53px;
    font-weight: 700;
}
.course-card-container{
    width: 100%;
    position: relative;
    gap: 2em;
    flex-direction: row;
}
.course-card{
    width: calc(100%/3);
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 0;
    border-radius: 0;
    box-shadow: 0px 4px 30px 2px rgba(0, 0, 0, 0.25);
    padding: 20px 20px 0 20px;
}
.course-card img{
    width: 100%;
}
.course-card-content{
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    width: 100%;
}
.course-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.course-card-content .course-content h3{
    font-size: 17px;
    font-weight: 700;
    color: #000;
}
.course-card-content .course-content p{
    font-size: 13px;
    font-weight: 300;
    color: #000;
}
.course-card:last-child .course-card-content .course-content h3, .course-card:first-child .course-card-content .course-content h3{
    margin-bottom: 30px;
}
.btn-learnMore{
    background-color: #000;
    border-radius: 0;
    font-size: 13px;
    padding: 8px 20px;
    font-weight: 500;
    border: 0;
    outline: 0;
    box-shadow: none;
    color: #fff!important;
    transition: 0.5s ease all;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
}
.btn-learnMore:hover{
    background-color: #fff;
    color: #000 !important;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
}
.btn:focus{
    box-shadow: none;
}
/* .seeAllButton{
    margin-top: 30px;
} */
/* .seeAllButton .btn-seeAll{
   padding: 10px 30px;
   font-size: 18px;
   font-weight: 500;
   box-shadow: 0 2px 5px rgba(0,0,0,0.5);
   border-radius: 10px;
   color: #000;
   transition: 0.5s ease all;
}
.seeAllButton .btn-seeAll svg{
    margin-left: 15px;
}
.seeAllButton .btn-seeAll:hover{
    background-color: #000;
    color: #fff;
    box-shadow: 0 5px 5px rgba(0,0,0,0.5);
} */

@media (min-width: 1500px) {
    .courses-container{
        max-width: 1300px;
    }
    .course-card{
        height: 380px;
    }
    .course-card-content .course-content h3{
        font-size: 20px;
    }
    .course-card-content .course-content p{
        font-size: 17px;
    }
}
@media (max-width: 1300px){
    .course-card{
        height: 400px;
    }
}
@media (max-width: 1250px){
    .course-card{
        height: 400px;
    }
    .course-card-container{
        gap: 1.5em;
    }
}
@media (max-width: 980px){
    .course-card{
        height: 360px;
        padding: 15px 15px 0 15px;
    }
    .course-card-content .course-content h3{
        font-size: 15px;
    }
}
@media (max-width: 650px){
    .course-card-container{
        flex-direction: column;
        width: 100%;
    }
    .course-card{
        width: 310px;
        height: 380px;
    }
    .course-card-content .course-content h3{
        font-size: 17px;
    }
    .coursesSection{
        margin-top: 0;
        padding-top: 2em;
    }

}
