.header{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(2px);
    transition: 0.5s ease;
}
header.scrolling {
    background-color: #fff; 
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    /* height: 90px; */
    transition: 0.5s ease;
  }
  /* header.scrolling .site-logo{
    width: 7%;
  } */
.navbar-container{
    width: 100%;
    max-width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.site-logo{
    width: 10%;
    transition: 0.5s ease;
}
.site-logo img{
    width: 80%;
}
.navbar-menu-list{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}
.navbar-list{
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    gap: 2em;
}
.navbar-list li a{
     color: #000;
     font-size: 17px;
     font-weight: 400;
}
.navbar-list li:hover a{
   /* font-weight: 500; */
   text-decoration: none;
}
.navbar-list li{
    padding: 0 10px;
    position: relative;
    cursor: pointer;
}
.navbar-list li::before{
    content: '';
    height: 2px;
    width: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #000;
    transition: 0.3s ease all ;
    border-radius: 50px;
}
.navbar-list li:hover::before{
   width: 100%;
}
.navbar-list li.active::before{
    width: 100%;
 }
 .navbar-list li.active a{
    font-weight: 500;
 }
 .mobile-menu-bar{
    display: none;
 }
 .mobile-navbar-menu{
    display: none;
 }

 @media (min-width: 1500px) {
    .navbar-container{
        width: 1300px;
    }
}
@media (max-width: 980px) {
    .header{
        overflow: visible;
    }
    .navbar-container{
        align-items: center;
    }
    .site-logo{
        width: 15%;
    }
    .mobile-menu-bar{
        display: block;
     }
     .navbar-menu-list{
        display: none;
     }
     .mobile-menu-bar svg{
        font-size: 30px;
        color: #000;
     }
     .hamburger-mobile{
        background: none;
        border: 0;
     }
     .mobile-navbar-menu{
        position: absolute;
        right: -400px;
        top: 90px;
        z-index: 1;
        background: #fff;
        width: 400px;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 50px;
        opacity: 0;
        padding-top: 80px;
        transition: 0.5s linear;
        box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.5);
     }
     .mobile-navbar-menu.active{
        right: 0;
        opacity: 1;
     }
     .mobile-navbar-menu .navbar-list{
        flex-direction: column;
        line-height: 3;
        align-items: flex-start;
     }
     .mobile-navbar-menu .navbar-list li a{
         font-size: 30px;
         font-weight: 500;
     }
     .mobile-navbar-menu .navbar-list li:hover a{
        font-weight: 700;
     }
     .mobile-navbar-menu .navbar-list li.active a{
        font-weight: 700;
        font-size: 35px;
     }
}
@media (max-width: 768px){
    .site-logo{
        width: 20%;
    }
}
@media (max-width: 610px){
    .site-logo{
        width: 25%;
    }
}
@media (max-width: 510px){
    .site-logo{
        width: 30%;
    }
    .mobile-navbar-menu{
        width: 300px;
        right: -300px;
        opacity: 0;
    }
    .mobile-navbar-menu.active{
        right: 0;
        opacity: 1;
     }
     .mobile-navbar-menu .navbar-list li a{
        font-size: 20px;
     }
     .mobile-navbar-menu .navbar-list li.active a{
        font-weight: 700;
        font-size: 30px;
     }
     .mobile-navbar-menu .navbar-list{
        line-height: 2;
     }
     .mobile-navbar-menu .navbar-list li{
        margin-bottom: 10px;
     }
     .mobile-navbar-menu .navbar-list li:last-child{
        margin-bottom: 0;
     }
     .mobile-menu-bar svg{
        font-size: 25px;
     }
}
@media (max-width: 380px){
    .site-logo{
        width: 40%;
    }
}