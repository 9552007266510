.heroSectionOfHome{
    width: 100vw;
    height: 100vh;
    /* padding-bottom: 3em; */
    position: relative;
    overflow: hidden; 
}
.hero-container{
    width: 100%;
    height: 100%;
    /* background-color:  #7D7445;
    ; */
    background-image: url('../../../../assets/background-hero-home.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    position: relative;
    /* overflow: hidden; */
}

/* .image-flower-glow{
    width: 12%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index:  1;
} */
/* .image-flower-glow img{
    width: 100%;
    transform: scale(0);
    transform-origin: left center;
    animation: zoomInRight 3s ease forwards;
} */
/* @keyframes zoomInRight {
    0%{
        opacity: 0;
        transform: scale(0)  translateZ(-100%);
    }
    100%{
        opacity: 1;
        transform: scale(1) translateZ(0); 
    }
} */
.content-container{
    width: 100%;
    max-width: 85%;
    height: 100%;
    position: relative;
}
.content-container .content-text{
   width: 100%;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
/* .content-image{
   text-align: right;
   position: absolute;
   right: 2%;
   z-index: 2;
   width: 45%;
   bottom: 0;
   animation: zoomIn 5s ease, slideRight 7s ease;
   animation-fill-mode: forwards;
}   */
/* @keyframes zoomIn {
    0% {
       width: 20%;
    }
    100% {
        width: 45%;
    }
} */
/* @keyframes pause {
    0%, 100% {
       right: 40%;
    }
} */
/* @keyframes slideRight {
    0% {
        right: 30%;
     }
     40% {
        right: 30%;
     }
     100% {
        right: 2%;
     }
} */

/* .content-image img{
    width: 85%;
    height: 100%;
    display: block;
    overflow: hidden;
    margin-left: auto;
    object-fit: cover;

} */
/* .wave{
    width: 100%;
    overflow: hidden;
    z-index: 3;
    position: absolute;
    bottom: 0;
} */
/* .wave img{
    width: 100%;
} */
.content-text h1{
    color: #ffff;
    font-size: 60px;
    font-weight: 700;
}
.content-text h5{
    color: #ffff;
    font-size: 22px;
    font-weight: 300;
    margin-top: -5px;
}
.content-text a button {
    color: #ffff;
    font-size: 20px;
    font-weight: 700;
    box-shadow: none;
    outline: 0;
    /* border: 0; */
    border: 2px solid transparent;
    background-color: #000;
    padding: 10px 35px;
    margin-top: 15px;
    border-radius: 0;
    transition: 0.5s ease all;
}
.content-text a button:hover{
    background-color: #fff;
    color: #000;
    box-shadow: none;
    border: 2px solid transparent;
    background: linear-gradient(#fff,#fff) padding-box,
    linear-gradient(102.69deg, #706633 33.43%, rgba(163, 151, 89, 0) 132.99%) border-box;

}
/* .color-box{
    z-index: 1;
    position: absolute;
    width: 45%;
    height: 80%;
    padding: 10px;
    right: 20px;
    top: 0;
    animation: rotate 7s linear infinite;
}
@keyframes rotate {
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
} */
/* .color-box .color-box-1{
    width: 250px;
    height: 230px;
    flex-shrink: 0;
    border-radius: 291px;
    background: rgba(255, 255, 255, 0.40);
    filter: blur(40px);
    position: absolute;
    top: 5%;
    left: 25%;
} */
/* .color-box .color-box-2{
    width: 370px;
    height: 350px;
    flex-shrink: 0;
    border-radius: 398px;
background: rgba(195, 187, 146, 0.60);
filter: blur(40px);
position: absolute;
top: 45%;
left: 40%;

} */
/* .color-box .color-box-3{
position: absolute;
top: 60%;
left: 0;
} */
/* .color-box .color-box-3 svg{
    width: 200px;
height: 200px;
flex-shrink: 0;
fill: #ADA162;
}  */
@media (min-width: 1500px) {
    .content-container{
        width: 1300px;
    }
}
@media (max-width: 920px){
    .content-text h1{
        font-size: 50px;
    }
}
@media (max-width: 840px){
    .content-text h1{
        font-size: 40px;
    }
}
@media (max-width: 768px){
    .content-text h5{
        font-size: 18px;
    }
}
@media (max-width: 620px){
    .content-text h1{
        font-size: 35px;
    }
    .content-text h5{
        font-size: 16px;
    }
}
@media (max-width: 520px){
    .content-text h1{
        font-size: 50px;
    }
    .content-text h5{
        font-size: 18px;
        margin-top: 10px;
    }
    .content-text a button{
        font-size: 18px;
    }
}