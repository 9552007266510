@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
.weight-gain-main-page{
    width: 100vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #FFF;
    flex-grow: 1;
    align-self: stretch;

}
.weight-gain-line-up{
    width: 100%;
    height: 10px;
    background-color: #16A086;
}
.weight-gain-main-container{
    width: 100%;
    background-color: #F0F0F0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em 0 2em 0;
}
.weight-gain-heading-container{
    width: 85%;
    position: relative;
    text-align: center;
    padding: 3em 0 1em 0;
}
.weight-gain-heading-container h1{
    font-size: 55px;
    font-weight: 800;
    text-transform: capitalize;
    font-style: italic;
    color: #16A086;
 } 
 .weight-gain-heading-container h2{
     color: #424242;
     font-size: 31px;
     font-weight: 500;
     line-height: 1.3em;
     letter-spacing: 0.8px;
 }
 .weight-gain-book-content{
    width: 85%;
    position: relative;
    flex-direction: row;
    padding: 1em 0;
    gap: 2em;
}
.weight-gain-book-image-column{
    width: 44%;
}
.weight-gain-book-image-column img{
    width: 100%;
}
.weight-gain-book-animation-column{
    width: 10%;
}
.weight-gain-book-content-column{
    width: 46%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    background-color: #F8FDFF;
    border: 2px solid #185180;
    border-radius: 7px;
}
.weight-gain-book-content-column h3{
   text-align: center;
   color: #185180;
   font-size: 27px;
   font-weight: 600;
   text-transform: capitalize;
}
.weight-gain-book-content-column p{
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
    color: #4E4E4E;
    font-weight: 400;
    line-height: 1.6em;
    letter-spacing: 0.8px;
    text-transform: capitalize;
}
.weight-gain-book-content-column .weight-gain-access-button-link{
  background-color: #EEB300;
  width: 100%;
  text-align: center;
  font-family: 'Oswald', sans-serif;
  padding: 14px;
  border: 3px solid #0D2551;
  border-radius: 5px;
  animation: shake 1s infinite;
  animation-delay: 2s;
}
@keyframes shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-5px); /* Adjust the left shake distance as needed */
    }
    50% {
      transform: translateX(5px); /* Adjust the right shake distance as needed */
    }
    75% {
      transform: translateX(-5px); /* Adjust the left shake distance as needed */
    }
    100% {
      transform: translateX(5px); /* Adjust the right shake distance as needed */
    }
  }
.weight-gain-book-content-column .weight-gain-access-button-link:hover{
   background-color:#EEB300 ;
   cursor: text;
}
.weight-gain-book-content-column .weight-gain-access-button-link .btn-weight-gain-get-access{
    font-size: 26px;
    color: #0D2551;
     cursor: text;
     line-height: 0.9em;
     font-weight: 600;
}
.weight-gain-click-here-button-container{
    width: 70%;
    display: flex;
    justify-content: center;
    position: relative;
    /* align-items: center; */
    margin-top: 1em;
    gap: 1em;
}
.btn-click-here{
  width: 84%;
  text-align: center;
  font-family: "Oswald", Sans-serif;
  background-color: #EEB300;
  padding: 20px 0;
  border-radius: 5px;
 
}
.button-click-here-link{
    width: 100%;
}
.button-click-here-link .btn-click-here-button{
    font-size: 48px;
    font-weight: 500;
    line-height: 0.8em;
    width: 100%;
    color: #000000;
}
.click-here-left-arrow{
    width: 8%;
    text-align: right;
}
.click-here-left-arrow img{
    width: 100%;
}
.click-here-right-arrow{
    width: 8%;
}
.click-here-right-arrow img{
    width: 100%;
}
.btn-click-here .btn-click-here-second-text{
    font-size: 30px;
    line-height: 0.8em;
    color:#000000;
}
.weight-gain-card-section{
    background-color: #fff;
    position: relative;
    padding: 1em 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.weight-gain-book-card-container{
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2em 0;
    position: relative;
}
.weight-gain-book-card-container-heading{
    text-align: center;
    margin-bottom: 20px;
}
.weight-gain-book-card-container-heading h2.heading-1{
  color: #16A086;
  font-size: 39px;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: 0.8px;  
}
.weight-gain-book-card-container-heading h2.heading-2{
    color: #2D2D2D;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.4em;
    letter-spacing: 0.8px;
}
.weight-gain-book-card-container .weight-gain-card-container-content{
   display: grid;
   grid-template-columns: 1fr 1fr;
   gap: 2em; 
}
.weight-gain-card-container-content .card{
    width: 100%;
    display: flex;
    height: 330px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    background-color: transparent;
    background-image: linear-gradient(90deg, #FFF4F2 0%, #F8E8E5 100%);
    border: 2px solid #16A086;
    padding: 10px 15px;
    border-radius: 10px;
}
.weight-gain-card-container-content .card:nth-child(3), .weight-gain-card-container-content .card:nth-child(4){
    height: 330px;
}
.card-container-box-header{
    display: flex;
    padding: 15px 10px;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    width: 100%;
    gap: 1em;
    border-radius: 7px;
    margin-bottom: 20px;
}
.card-container-image-link{
    height: 65px;
    width: 65px;
    background-color: #60B8A7;
    text-align: center;
    line-height: 65px;
    border-radius: 10%;
}
.card-container-image-link img{
    width: 55%;
}
.card-container-box-header h2{
    font-size: 22px;
    color: #051A2D;
    font-weight: 600;
    line-height: 35px;
}
.card-container-box-content p{
    font-size: 19px;
    color: #051A2D;
    font-weight: 400;
    line-height: 1.5em;
}
.weight-gain-list-section{
    width: 100%;
    background-color: #F0F0F0;
    padding: 1em 0 2em 0;
}
.weight-gain-list-container{
    width: 100%;
    padding: 2em 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.weight-gain-list-container-heading{
    width: 85%;
}
.weight-gain-list-container-heading h2{
    font-size: 37px;
    text-align: center;
    color: #16A086;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 1.2em;
    letter-spacing: 0.8px;
}
.weight-gain-list-container .weight-gain-list-content-box{
    padding: 20px 20px 20px 40px;
    background-color: #fff;
    width: 75%;
    border-radius: 22px;
}
.weight-gain-list-content-box ul li{
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}
.weight-gain-list-content-box ul li .weight-gain-list-group-icon{
    font-size: 30px;
    margin-right: 20px;
    color: #16A086;
}
.weight-gain-list-content-box ul li .weight-gain-list-group-text{
    font-size: 20px;
    color: #000;
    font-weight: 400;
}
.weight-gain-list-footer-content{
    margin-top: 15px;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.weight-gain-list-footer-content h2{
    font-size: 30px;
    font-weight: 500;
    color: #424242;
    line-height: 1.3em;
    letter-spacing: 0.8px;
}
.weight-gain-list-footer-button{
    width: 90%;
    text-align: center;
  font-family: "Oswald", Sans-serif;
  background-color: #EEB300;
  padding: 20px 0;
  border-radius: 5px;
  margin-top: 20px;
}
.btn-weight-gain-list-footer{
    font-size: 48px;
    font-weight: 500;
    line-height: 0.8em;
    width: 100%;
    color: #000;
}
.btn-weight-gain-list-footer-second-text{
    font-size: 30px;
    line-height: 0.8em;
    color: #000;
}
.btn-weight-gain-list-footer:hover{
    color: #000;
}
.weight-gain-testimonial-section{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    background-color: #fff;
    padding: 2em 0;
}
.weight-gain-container-testimonial{
    width: 85%;
    padding: 2em 0 5em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.weight-gain-container-testimonial-heading h2{
    color: #424242;
    font-size: 32px;
    font-weight: 500;
    line-height: 1.3em;
    letter-spacing: 0.8px;
}
.weight-gain-testimonial-image-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: relative;
    width: 100%;
    gap: 1em;
    height: 350px;
    margin-top: 30px;
}
.testimonial-image-review{
    width: 100%;
    height: 100%;
    border: 2px solid #FF2E57;
    border-radius: 7px;
}
.testimonial-image{
    height: 100%;
}
.weight-gain-container-testimonial .weight-gain-book-image{
    width: 50%;
    margin-top: 3em;
}
.weight-gain-container-testimonial .weight-gain-book-image img{
    width: 100%;
}
.weight-gain-container-testimonial .weight-gain-testimonial-button{
    width: 65%;
    text-align: center;
    font-family: "Oswald", Sans-serif;
    background-color: #EEB300;
    padding: 20px 0;
    border-radius: 5px;
    margin-top: 20px;
}
.btn-weight-gain-testimonial-button{
    font-size: 48px;
    font-weight: 500;
    line-height: 0.8em;
    width: 100%;
    color: #000;
}
.btn-weight-gain-testimonial-button-second-text{
    font-size: 30px;
    line-height: 0.8em;
    color: #000;
}
.btn-weight-gain-testimonial-button:hover{
   color: #000;
}
.weight-gain-footer{
    width: 100vw;
    padding: 10px 0;
    background: #F8E8E5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid #FF2E57;
    /* box-shadow: 0 0 5px rgba(0,0,0,0.5); */
}
.weight-gain-footer-container{
    width: 100%;
    max-width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 1em;
}
.weight-gain-price-column{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.weight-gain-price-column .price-content h2{
    font-size: 30px;
    font-weight: 700;
    font-family: "Montserrat", Sans-serif;
}
.weight-gain-price-column .price-content h2 .discount-price{
    color: #EEB300;
}
.weight-gain-price-column .price-content h2 .regular-price{
    text-decoration: line-through;
    margin-left: 10px;
}
.weight-gain-get-instant-access-content svg{
    font-size: 22px;
    color: #EEB300;
}
.weight-gain-get-instant-access-text{
    font-size: 22px;
    margin-left: 10px;
    font-weight: 500;
    font-family: "Montserrat", Sans-serif;
}
.btn-weight-gain-buy-now-button{
    font-family: "Oswald", Sans-serif;
    background-color: #EEB300; 
    font-size: 36px;
    font-weight: 700;
    border: 2px solid #c1c1c1;
    border-radius: 13px;
    padding: 8px 50px 14px 50px;
    line-height: 1em;
    color: #000;
}
.btn-weight-gain-buy-now-button:hover{
    color: #000;
}
@media (max-width: 1220px) {
    .weight-gain-card-container-content .card{
        height: 360px;
    }
    .weight-gain-card-container-content .card:nth-child(3), .weight-gain-card-container-content .card:nth-child(4){
        height: 360px;
    }
}

@media (max-width: 1120px){
    .weight-gain-heading-container,.weight-gain-book-content,.weight-gain-click-here-button-container,.weight-gain-book-card-container{
        width: 90%;
    }
    .weight-gain-book-content-column{
        width: 50%;
    }
    .weight-gain-book-image-column{
        width: 40%;
    }
    .button-click-here-link .btn-click-here-button{
        line-height: 1em;
    }
    .weight-gain-container-testimonial .weight-gain-testimonial-button{
        width: 80%;
    }
}
@media (max-width: 1080px){
    .btn-weight-gain-list-footer{
        line-height: 1em;
    } 
}
@media (max-width: 1050px){
    .weight-gain-heading-container,.weight-gain-click-here-button-container,.weight-gain-book-card-container{
        width: 95%;
    }
    .weight-gain-container-testimonial .weight-gain-testimonial-button{
        width: 85%;
    }
    .weight-gain-card-container-content .card{
        height: auto;
    }
    .weight-gain-card-container-content .card:nth-child(3), .weight-gain-card-container-content .card:nth-child(4){
        height: auto;
    }
}
@media (max-width: 990px){
    .weight-gain-heading-container h1{
        font-size: 50px;
    }
    .weight-gain-book-content-column .weight-gain-access-button-link .btn-weight-gain-get-access {
        line-height: 1em;
    }
    .weight-gain-card-container-content .card{
        height: auto;
    }
    .weight-gain-card-container-content .card:nth-child(3), .weight-gain-card-container-content .card:nth-child(4){
        height: auto;
    }
    .weight-gain-container-testimonial .weight-gain-testimonial-button{
        width: 90%;
    }
    .btn-weight-gain-testimonial-button{
        line-height: 1em;
    }
}
/* @media (max-width: 868px){
    .card-container-content .card{
        height: 450px;
    }
    .card-container-content .card:nth-child(3), .card-container-content .card:nth-child(4){
        height: 380px;
    }
} */
@media (max-width: 830px){
    .weight-gain-heading-container h1{
        font-size: 44px;
    }
    .weight-gain-heading-container h2{
        font-size: 28px;
    }
    .weight-gain-book-content{
        flex-direction: column;
    }
    .weight-gain-book-image-column{
        width: 60%;
    }
    .weight-gain-book-animation-column{
       display: none;
    }
    .weight-gain-book-content-column{
        width: 80%;
    }
    .button-click-here-link .btn-click-here-button{
        font-size: 42px;
    }
    /* .card-container-content .card{
        height: 480px;
    }
    .card-container-content .card:nth-child(3), .card-container-content .card:nth-child(4){
        height:420px;
    } */
}
@media (max-width: 768px){
    /* .card-container-content .card{
        height:500px;
    }
    .card-container-content .card:nth-child(3), .card-container-content .card:nth-child(4){
        height:450px;
    } */
    .btn-weight-gain-testimonial-button{
        font-size: 42px;
    }
    .btn-weight-gain-buy-now-button{
        font-size: 30px;
        padding: 6px 30px;
    }
    .weight-gain-footer-container{
        max-width: 85%;
    }
}
@media (max-width: 678px){
    .weight-gain-heading-container h1{
        font-size: 40px;
    }
    .weight-gain-heading-container h2{
        font-size: 25px;
    }
    .button-click-here-link .btn-click-here-button{
        font-size: 38px;
    }
    .btn-click-here .btn-click-here-second-text{
        font-size: 22px;
    }
    .weight-gain-book-card-container .weight-gain-card-container-content{
       display: flex;
       flex-direction: column;
    }
    .weight-gain-book-card-container{
        width: 80%;
    }
    /* .card-container-content .card{
        height: 380px;
    }
    .card-container-content .card:nth-child(3), .card-container-content .card:nth-child(4),.card-container-content .card:nth-child(1){
        height:320px;
    } */
    .weight-gain-list-container .weight-gain-list-content-box{
        width: 90%;
    }
    .weight-gain-list-footer-content{
        width: 90%;
    }
    .weight-gain-container-testimonial-heading h2{
        font-size: 28px;
    }
    .weight-gain-testimonial-image-content{
        flex-direction: column;
        height: auto;
    }
    .weight-gain-container-testimonial .weight-gain-book-image{
        width: 80%;
    }
    .weight-gain-footer-container{
        max-width: 90%;
    }
    
}
@media (max-width: 610px){
    .weight-gain-heading-container h1{
        font-size: 33px;
    }
    .weight-gain-heading-container h2{
        font-size: 20px;
    }
    .weight-gain-click-here-button-container{
        width: 100%;
    }
    .book-card-container-heading h2.heading-1{
        font-size: 35px;
    }
    .book-card-container-heading h2.heading-2{
        font-size: 25px;
    }
    .weight-gain-list-container{
        max-width: 100%;
    }

    .weight-gain-list-container .weight-gain-list-content-box{
        width: 100%;
        padding: 20px 20px 20px 20px;
    }
    .weight-gain-list-content-box ul li .weight-gain-list-group-icon{
        font-size: 25px;
    }
    .weight-gain-list-content-box ul li .weight-gain-list-group-text{
        font-size: 18px;
        line-height: 1.5;
    }
    .weight-gain-list-container-heading h2{
        font-size: 35px;
    }
    .weight-gain-book-content-column p{
        font-size: 18px
    }
    .weight-gain-book-content-column{
        width: 95%;
    }
    .weight-gain-list-footer-content{
        width: 95%;
    }
    .weight-gain-list-footer-content h2{
        font-size: 25px;
    }
    .btn-weight-gain-list-footer{
        font-size: 38px;
    }
    .btn-weight-gain-list-footer-second-text{
        font-size: 25px;
    }
    .btn-weight-gain-testimonial-button{
        font-size: 38px;
    }
   
}
@media (max-width: 588px){
    /* .card-container-content .card{
        height: 400px;
    }
    .card-container-content .card:nth-child(3), .card-container-content .card:nth-child(4),.card-container-content .card:nth-child(1){
        height:350px;
    } */
    .card-container-box-header h2{
        font-size: 18px;
    }
}
@media (max-width: 520px){
    .weight-gain-heading-container h1{
        font-size: 30px;
    }
    .weight-gain-heading-container h2{
        font-size: 18px;
    }
    .weight-gain-click-here-button-container{
        width: 100%;
    }
    .book-card-container{
        width: 95%;
    }
    .card-container-box-content p{
        font-size: 15px;
    }
    .weight-gain-book-content-column p{
        font-size: 15px;
    }
    .weight-gain-book-content-column h3{
        font-size: 20px;
    }
    .weight-gain-book-content-column{
        padding: 10px;
    }
    .weight-gain-book-content-column .weight-gain-access-button-link .btn-weight-gain-get-access{
        font-size: 20px;
    }
    .btn-weight-gain-list-footer{
        font-size: 30px;
    }
    .btn-weight-gain-list-footer-second-text{
        font-size: 20px;
    }
    .btn-weight-gain-testimonial-button{
        font-size: 30px;
    }
    .buynow-price-column .price-content h2{
        font-size: 25px;
    }
    .weight-gain-get-instant-access-text{
        font-size: 17px;
    }
    .btn-weight-gain-buy-now-button{
        font-size: 25px;
        padding: 10px 20px;
    }
    .weight-gain-price-column{
        width: 60%;
    }
    .btn-weight-gain-testimonial-button-second-text{
        font-size: 20px;
    }
   
}
@media (max-width: 420px){
    .card-container-content .card{
        height: 500px;
    }
    .card-container-content .card:nth-child(3), .card-container-content .card:nth-child(4),.card-container-content .card:nth-child(1){
        height: 450px;
    }
    .weight-gain-container-testimonial-heading h2{
        font-size: 25px;
    }
    .weight-gain-price-column .price-content h2{
        font-size: 20px;
    }
    .weight-gain-get-instant-access-text{
        font-size: 15px;
    }
    .weight-gain-get-instant-access-content svg{
        font-size: 20px;
    }
}
