.contactForm{
    width: 100vw;
    position: relative;
    padding: 5em 0;
}
.contact-form-container{
    width: 100%;
    max-width: 85%;
    position: relative;
    height: 750px;
    background-image: url('../../../../assets/background-footer-img.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    /* background-color: rgba(112, 102, 51, 0.82);
    background-blend-mode: overlay; */
    padding-right: 0;
    padding-left: 0;
    padding: 0;
    position: relative;
    box-shadow: 3px 5px 10px  rgba(0,0,0,0.5);
}
/* .contact-form-container.container::before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(112, 102, 51, 0.82);
    z-index: 1;
} */
.form-column{
    width: 50%;
    background-color: #ffff;
    height: 100%;
    padding: 3em 5em 3em 3em;
    z-index: 2;
    overflow: hidden;
}
.form-column .form-column-heading {
    width: 100%;
}
.form-column .form-column-heading h1{
   font-size: 40px;
   color: #000;
   font-weight: 600;
}
.form-column .form-column-heading h5{
    font-size: 18px;
    color: rgba(123, 123, 123, 1);
    font-weight: 300;
 }
 .flower-image{
    width: 280px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
    overflow: hidden;
 }
 .form-box .form-control{
    margin-bottom: 10px;
    height: 45px;
    border: 0;
    border-bottom: 1px solid rgba(152, 152, 152, 1);
    border-radius: 0;
    color: #000;
    font-size: 17px;
    padding-left: 5px;
 }
 .form-box .form-control:focus{
    box-shadow: none;
    border-bottom: 2px solid rgba(112, 102, 51, 1);
    ;
 }
 .form-box input::placeholder{
    color: rgba(150, 150, 150, 1);
    ;
 }
 .flower-image img{
    width: 100%;
 }
.image-column{
    width: 50%;
}
.form-box{
    margin-top: 3.5em;
}
.form-box button.btn-submit{
  padding: 12px 35px;
  border: 1px solid #000;
  font-size: 17px;
   float: right;
   margin-top: 10px;
   border-radius: 0;
   font-weight: 600;
   transition: 0.5s ease all;
   cursor: pointer;
   background-color: #fff;
}
.form-box button.btn-submit:hover{
    background-color: #000;
    color: #fff;
}
/* .react-tel-input .form-control{
    border: 0 !important;
    width: 100% !important;
    border-bottom: 1px solid rgba(152, 152, 152, 1) !important;
    border-radius: 0 !important;
    padding-left: 48px !important;
} */
/* .react-tel-input .form-control:focus{
    box-shadow: none !important;
    border-bottom: 2px solid rgba(112, 102, 51, 1) !important;
    ;
} */
/* .error-msg{
    font-weight: 500 !important;
} */
@media (min-width: 1500px) {
    .contact-form-container{
        width: 1300px;
    }
}
@media (max-width: 900px){
    .form-column{
        width: 60%;
    }
    .image-column{
        width: 40%;
    }
    .flower-image{
        width: 250px;
    }
    .contact-form-container{
        height: 700px;
    }
}
@media (max-width: 768px){
    .form-column{
        width: 70%;
    }
    .image-column{
        width: 30%;
    }
}
@media (max-width: 650px){
    .form-column{
        width: 100%;
    }
    .image-column{
        display: none;
    }
    .form-column .form-column-heading h1{
        font-size: 30px;
    }
    .form-column .form-column-heading h5{
        font-size: 16px;
    }
}
@media (max-width: 520px){
    .form-column{
        padding: 3em 1em 0 1em;
    }
    .form-box .form-control{
        font-size: 15px;
    }
    .form-box button.btn-submit{
        font-size: 15px;
    }
    .contact-form-container{
        height: 680px;
    }
    .flower-image{
        width: 230px;
    }
}
