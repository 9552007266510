.facilitiesAndService{
    width: 100vw;
    position: relative;
    padding: 3em 0;
}
.facilities-and-service-container{
    width: 100%;
    max-width: 85%;
    position: relative;
}
.container-heading h1{
   font-size: 53px;
   font-weight: 700;
   color: #000;
}
.facilities-and-service-content-container{
    width: 100%;
    margin-top: 3em;
    gap: 2em;
}
.content-container-row{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 95%;
    line-height: 5;
}
.content-container-row .content-box{
    width: calc(95%/4);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.content-container-row .content-box h4{
    font-size: 18px;
    color: #000;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
}
.content-container-row .content-box .svg-image{
    width: 85px;
    height: 85px;
    box-shadow: 0px 4px 10px 0px #00000040;
    border-radius: 50%;
    border: 2px solid #fff;
    text-align: center;
    margin-bottom: 20px;
    background-color: #fff;
    transition: 0.5s linear;
}
.content-container-row .content-box .svg-image img{
    width: 45px;
}
.content-container-row .content-box .svg-image:hover{
    box-shadow: none;
    border: 2px solid transparent;background: linear-gradient(#fff,#fff) padding-box,
    linear-gradient(180deg, #AB9A46 0%, rgba(137, 124, 61, 0) 100%) border-box;    
}
@media (min-width: 1500px) {
    .facilities-and-service-container{
        width: 1300px;
    }
}
@media (max-width: 980px){
    .content-container-row .content-box h4{
        font-size: 16px;
    }
    .content-container-row{
        width: 100%;
    }
    .content-container-row .content-box{
        width: calc(100%/4);
    }
}
@media (max-width: 790px){
    .content-container-row{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .content-container-row .content-box{
        width: 100%;
    }
    .container-heading h1{
        font-size: 40px;
    }
}
@media (max-width: 620px){
    .container-heading h1{
        font-size: 30px;
    } 
    .content-container-row{
        gap: 2em;
    }
    .content-container-row .content-box h4{
        font-size: 14px;
    }
    .content-container-row .content-box{
        margin-bottom: 20px;
        justify-content: flex-start;
        height: 120px;
    }
}