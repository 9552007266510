.lastSection{
    width: 100vw;
    position: relative;
    padding-top: 3em;
}
.container-join-us-section{
    width: 100%;
    max-width: 80%;
    position: relative;
}
.join-us-container-content{
    text-align: center;
}
.join-us-container-content h1{
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 20px;
}
.join-us-container-content p.paragraph-1{
    font-size: 17px;
    font-weight: 500;
}
.join-us-container-content p.paragraph-2{
    font-size: 15px;
    font-weight: 300;
}
.about-mentor {
    width: 100%;
    margin-top: 30px;
    position: relative;
    padding-bottom: 3em;
}
.container-about-mentor{
    width: 100%;
    max-width: 85%;
    position: relative;
}
.heading-of-mentor h3{
    font-size: 30px;
    color: #706633;
}
.heading-of-mentor .underline{
     height: 3px;
     width: 200px;
     background-color: #706633;
}
.conatiner-about-mentor-content{
    width: 100%;
    max-width: 85%;
    position: relative;
    flex-direction: row;
    gap: 3em;
    margin-top: 30px;
}
.about-mentor-image{
    width: 50%;
}
.about-mentor-image img{
    width: 100%;
}
.about-mentor-content{
    width: 50%;
}
.about-mentor-content h1{
    font-size: 33px;
    font-weight: 700;
}
.about-mentor-content p.text-1{
    font-size: 18px;
    font-weight: 400;
}
.about-mentor-content .paragraph{
    font-size: 15px;
    font-weight: 300;
    background-color: #000;
    color: #fff;
    padding: 10px 20px 10px 20px;
    margin-left: -5em;
}
.about-mentor-content .content-list li{
    margin-bottom: 20px;
}
.about-mentor-content .content-list li:last-child{
    margin-bottom: 0;
}
.about-mentor-content .content-list li h5{
    font-size: 18px;
    font-weight: 700;
}
.about-mentor-content .content-list li p{
    font-size: 15px;
    font-weight: 300;
}
.image-background{
    position: absolute;
    bottom: -6em;
    left: 0;
    z-index: -1;
}
.image-background img{
    width: 100%;
}
.quate-text-content{
    margin-top: 1.5em;
    width: 100%;
    max-width: 85%;
    margin-bottom: 5em;
}
.quate-text-content .quate-text{
    width: 80%;
    text-align: center;
    padding: 2em;
    background:  rgba(148, 132, 47, 0.46);
    ;
    /* box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25); */
    border-radius: 0;
}
.quate-text-content .quate-text h4{
    font-size: 18px;
    font-weight: 400;
}
@media (min-width: 1500px) {
    .container-join-us-section {
        width: 1100px;
    }
    .container-about-mentor, .conatiner-about-mentor-content,.quate-text-content{
        width: 1300px;
    }
}
@media (max-width: 1080px){
    .about-mentor-image{
        width: 55%;
    }
    .about-mentor-content{
        width: 45%;
    }
}
@media (max-width: 890px){
    .conatiner-about-mentor-content{
        flex-direction: column;
    }
    .about-mentor-image{
        width: 100%;
    }
    .about-mentor-content{
        width: 100%;
    }
    .about-mentor-content .paragraph{
        margin-left: 0;
    }
    .quate-text-content .quate-text{
        width: 90%;
    }

}
@media (max-width: 768px){
    .join-us-container-content h1{
        font-size: 35px;
    }
}
@media (max-width: 520px)
{
    .join-us-container-content h1{
        font-size: 30px;
    }
    .join-us-container-content p.paragraph-1{
        font-size: 15px;
    }
    .container-join-us-section{
        max-width: 85%;
    }
    .heading-of-mentor h3{
        font-size: 20px;
    }
    .heading-of-mentor .underline{
        width: 120px;
    }
    .quate-text-content .quate-text h4{
        font-size: 16px;
    }
    .quate-text-content .quate-text{
        width: 100%;
    }
}