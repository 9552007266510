.thankYouEbook{
   width: 100vw;
   height: 100vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.container-thank-you-ebook{
   width: 100%;
   max-width: 85%;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.container-thank-you-ebook h1{
  font-size: 45px;
  text-align: center;
  font-weight: 700;

}
.color-change-green{
  color: #1bb51e;
}
.email-download-container-box{
  margin-top: 20px;
  border: 10px solid #1bb51e;
  padding: 30px 20px;
}
@media (max-width: 620px){
  .container-thank-you-ebook h1{
    font-size: 40px;
  }
}
@media (max-width: 520px){
  .container-thank-you-ebook h1{
    font-size: 30px;
  }
}