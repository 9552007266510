.middleHeading{
    width: 100vw;
    position: relative;
    height: 350px;
    background-color: rgba(151, 140, 83, 0.44);
    ;
    /* background-image: url('../../../../assets/background-image-middle.png'); */
    background-position: center left;
    background-repeat: no-repeat;
    background-size: cover;
}
.middle-heading-container{
    width: 100%;
    max-width: 85%;
}
.middle-heading-content h1{
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    color: #000;
}
.btn-contact{
    background-color: #000;
    padding: 8px 35px;
    color: #fff;
    margin-top: 10px;
    font-size: 17px;
    border-radius: 0;
    font-weight: 500;
    border: 2px solid transparent;
}
.btn-contact:focus{
    box-shadow: none;
}
.btn-contact:hover{
    background-color: #ffff;
    color: #000;
    box-shadow: none;
    border: 2px solid transparent;
    background: linear-gradient(#fff,#fff) padding-box,
    linear-gradient(102.69deg, #706633 33.43%, rgba(163, 151, 89, 0) 132.99%) border-box;
}
@media (min-width: 1500px) {
    .middle-heading-container{
        max-width: 1300px;
    }
}
@media (max-width: 768px) {
    .middle-heading-content h1{
        font-size: 35px;
    }
}
@media (max-width: 520px){
    .middle-heading-content h1{
        font-size: 28px;
    }
    .btn-contact{
        font-size: 15px;
    }
}