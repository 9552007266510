.TextSection{
    width: 100vw;
    padding: 3em 0 2em 0;
    position: relative;
}
.text-section-container{
    width: 100%;
    max-width: 85%;
    position: relative;
}
.text-container-content{
    text-align: center;
    width: 80%;
    padding: 30px 30px 10px 30px;
    margin-top: -8em;
    background-color: rgba(227, 227, 227, 0.5);
    backdrop-filter: blur(5px);
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}
.text-container-content p{
    font-size: 17px;
    font-weight: 500;
    color: #000;
}
.mission-conatiner-content{
    margin-top: 5em;
    text-align: center;
    width: 85%;
}
.mission-conatiner-content h4{
    font-size: 30px;
    font-weight: 700;
    color: #000;
}
.mission-conatiner-content p{
    font-size: 15px;
    font-weight: 300;
    color: #000;
}
@media (min-width: 1500px) {
    .text-section-container{
        width: 1300px;
    }
}
@media (max-width: 620px){
    .text-container-content{
        width: 100%;
        padding: 30px 10px 10px 10px;
    }
    .text-container-content p{
        font-size: 15px;
    }
    .mission-conatiner-content{
        width: 100%;
    }
    .mission-conatiner-content h4{
        font-size: 25px;
    }
    .mission-conatiner-content p{
        font-size: 15px;
    }
}