.heroSectionAbout{
    width: 100vw;
    position: relative;
    /* height: auto; */
}
.container-about{
    width: 100%;
    height: 600px;
    position: relative;
    background-image: url('../../../../assets/background-about.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}
.container-heading-box-about{
    width: 100%;
    max-width: 85%;
    position: relative;

}
.container-heading-box-about h1{
    font-size: 50px;
    color: #fff;
    font-weight: 700;
    z-index: 1;
    line-height: 1.3;
}
.wave-line-about{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.wave-line-about img{
    width: 100%;
}
.about-flower-image-2{
    /* width: %; */
    position: absolute;
    right: 0;
    top: 0;
}
.flower-image-2 img{
    width: 100%;
}
.about-flower-image-1{
    position: absolute;
    left: 0;
    top: -5em;
}
.flower-image-1 img{
    width: 100%;
}
@media (min-width: 1500px) {
    .container-heading-box-about{
        width: 1300px;
    }
}
@media (max-width: 768px){
    .container-heading-box-about h1{
        font-size: 40px;
    }
    .container-about{
        height: 450px;
    }
}
@media (max-width: 520px){
    .container-heading-box-about h1{
        font-size: 30px;
    }
    .container-about{
        height: 400px;
        padding-top: 1em;
    }
}