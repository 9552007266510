.whyChooseUs{
    width: 100vw;
    position: relative;
    padding: 5em 0 2em 0;
}
.whyChoose-container{
    width: 100%;
    max-width: 85%;
    position: relative;
}
.whyChoose-container .container-heading {
  width: 100%;
  margin-bottom: 3em;
}
.whyChoose-container .container-heading h1{
    font-size: 42px;
    font-weight: 700;
    color: #000;
}
.whyChoose-container .content-container{
    max-width: 100%;
    gap: 1.5em;
    position: relative;
    flex-direction: row;
}
.whyChoose-container .content-container .image-box{
    width: 45%;
}
.whyChoose-container .content-container .image-box img{
    width: 100%;
}
.whyChoose-container .content-container .text-box{
    width: 55%;
    padding-left: 1em;
    padding-top: 2em;
}
.whyChoose-container .content-container .text-box p{
    font-size: 20px;
    width: 100%;
    font-weight: 400;
    /* line-height: 1.3; */
}
.quate-content{
    position: absolute;
    width: 70%;
    bottom: 27%;
    left: 27%;
    background-color: #fff;
    border: 2px solid #fff;
    border-image-slice: 1; 
    padding: 40px 0;
    border-radius: 0;
    box-shadow: 0 3px 10px rgba(0,0,0,0.5);
    vertical-align: middle;
    text-align: center;
    transition: 0.5s linear;
}
.quate-content h3{
    font-size: 25px;
    font-weight: 700;
}
.quate-content:hover{
    border: 2px solid transparent;
    background: linear-gradient(#fff, #fff) padding-box
    ,linear-gradient(92.63deg, #A6984C 30.23%, rgba(112, 102, 51, 0) 126.93%) border-box;
    box-shadow: 0px 4px 20px 0px #706633;

}
@media (min-width: 1500px) {
    .whyChoose-container{
        width: 1300px;
    }
}
@media (max-width: 1150px){
    .quate-content{
        width: 80%;
        left: 20%;
        bottom: 20%;
        padding: 30px;
    }
}
@media (max-width: 1100px){
    .quate-content{
        width: 80%;
        left: 20%;
        bottom: 20%;
        padding: 30px;
    }
    .whyChoose-container .content-container .image-box{
        width: 50%;
    }
    .whyChoose-container .content-container .text-box{
        width: 50%;
    }
}
@media (max-width: 920px){
    .whyChoose-container .content-container .image-box{
        width: 55%;
    }
    .whyChoose-container .content-container .text-box{
        width: 45%;
    }
    .quate-content{
        padding: 20px;
    }
}
@media (max-width: 830px){
    .whyChoose-container .content-container .image-box{
        width: 60%;
    }
    .whyChoose-container .content-container .text-box{
        width: 40%;
    }
    .quate-content{
        padding: 20px;
        bottom: 15%;
    }
}
@media (max-width: 790px){
    .quate-content{
        padding: 15px;
        bottom: 15%;
    }
    .quate-content h3{
        font-size: 20px;
        font-weight: 700;
    }
    .whyChoose-container .content-container .text-box p{
        font-size: 17px;
    }
}
@media (max-width: 690px){
    .whyChoose-container .content-container{
        flex-direction: column;
    }
    .quate-content{
        top: 40%;
        height: 80px;
    }
    .whyChoose-container .content-container .image-box{
        width: 70%;
    }
    .whyChoose-container .content-container .text-box{
        width: 100%;
    }
    .whyChoose-container .container-heading h1{
        font-size: 35px;
    }
    
}
@media (max-width: 520px){
    .whyChoose-container .content-container .image-box{
        width: 100%;
    }
    .quate-content{
        top: 35%;
        height: 90px;
        width: 90%;
        left: 12%;
    }
    .whyChoose-container .content-container .text-box p{
        font-size: 15px;
    }
    .quate-content h3{
        font-size: 16px;
        font-weight: 700;
    }
    .whyChoose-container .container-heading h1{
        font-size: 30px;
    }
}


