.main-section-course{
    width: 100vw;
    position: relative;
    overflow: hidden;
}
.container-course-main-section{
    width: 100%;
    max-width: 85%;
    position: relative;
}
.container-course-main-section-row{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    gap: 1.5em;
}
.card-course-main{
    width: 100%;
    height: 23em;
    display: flex;
    flex-direction: column;
    border: 0;
    border-radius: 0;
    box-shadow: 0px 4px 30px 2px rgba(0, 0, 0, 0.25);
}
.card-course-main .main-card-content{
    padding: 20px 25px;
    color: #000;
}
.card-course-main .main-card-content h5{
  font-size: 17px;
  font-weight: 700;
}
.card-course-main .main-card-content p{
    font-size: 14px;
}
.card-course-main .main-card-content ul.list-course-card{
    line-height: 2.5;
    padding-left: 20px;
}
.card-course-main .main-card-content ul.list-course-card li{
    border: 0;
    background: none;
    font-size: 13px;
    padding: 0;
}
.btn-buyNow{
    background-color: #000;
    border-radius: 0;
    font-size: 13px;
    padding: 8px 20px;
    font-weight: 500;
    border: 0;
    outline: 0;
    box-shadow: none;
    color: #fff!important;
    transition: 0.5s ease all;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
}
.btn-buyNow:hover{
    background-color: #fff;
    color: #000 !important;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
}
.btn:focus{
    box-shadow: none;
}
.button-buyNow{
    width: 100%;
    text-align: center;
}
.container-course-main-section-row:nth-child(2) .card-course-main:nth-child(1) .button-buyNow{
    margin-top: 30px;
}
/* .card-course-main:nth-child(1) .button-buyNow{
    margin-top: 1.5em;
}
.card-course-main:nth-child(3) .button-buyNow{
    margin-top: 1.5em;
} */

@media (min-width: 1500px) {
    .container-course-main-section{
        width: 1300px;
    }
    .card-course-main .main-card-content ul.list-course-card li{
        font-size: 15px;
    }
    .card-course-main{
       height: 25em;
    }
    /* .card-course-main:nth-child(2) .button-buyNow{
        margin-top: 2.5em;
    } */
    .container-course-main-section-row:nth-child(2) .card-course-main:nth-child(1) .button-buyNow{
        margin-top: 50px ;
    }
}
@media (max-width: 950px){
    .card-course-main{
        height: 21em;
    }
    .card-course-main .main-card-content{
        padding: 20px 15px;
    }
}
@media (max-width: 820px){
    .container-course-main-section-row{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .card-course-main{
        width: 100%;
    }
}
@media (max-width: 620px){
    .container-course-main-section-row{
        grid-template-columns: 1fr;
    }
    .card-course-main{
        width: 100%;
        margin-bottom: 30px;
        height: 25em;

    }
}
@media (max-width: 520px){
    .card-course-main{
       height: 25em;
       width: 100%;
    }
}