.aboutCard{
    width: 100vw;
    padding: 2em 0 3em 0;
    position: relative;
}
.container-about-card-heading{
    width: 100%;
    max-width: 85%;
    text-align: center;
    position: relative;
    margin-bottom: 30px;
}
.container-about-card-heading h1{
    font-size: 40px;
    font-weight: 700;
}
.about-card-content{
    width: 100%;
    position: relative;
    background-color: rgba(124, 111, 44, 0.46);
    padding: 5em 0;
}
.about-card-content-container{
    width: 100%;
    max-width: 85%;
    position: relative;
}
.about-card-content-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2.5em;
    margin-bottom: 2.5em;
    flex-wrap: wrap;
}
.about-card-content-row:last-child{
    margin-bottom: 0;
}
.card-about-content-box{
    width: 340px;
    height: 430px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 20px 20px 20px;
    position: relative;
    border: 0;
    border-radius: 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: 0.5s ease all;
}
.card-about-content-box img{
    width: 80%;
}
.card-content-text{
    text-align: center;
    margin-top: 35px;
}
.card-content-text h5{
    font-size: 27px;
    color: #897E45;
    margin-bottom: 35px;
}
.card-content-text p{
    font-size: 15px;
    color: #000;
    font-weight: 300;
}
.about-card-content-row:first-child .card-about-content-box:nth-child(2) .card-content-text h5{
    margin-bottom: 10px;
}
.about-card-content-row:last-child .card-about-content-box:nth-child(1) .card-content-text h5{
    margin-bottom: 10px;
}
.card-about-content-box::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 5;
    background-color: #706633;
    transition: 0.5s ease all;
}
.card-about-content-box:hover::after{
    height: 20px;
}
.card-about-content-box:hover{
    padding: 50px 20px 20px 20px;
    box-shadow: 0px 4px 5px 0px rgba(112, 102, 51, 0.9);
}
@media (min-width: 1500px) {
    .container-about-card-heading{
        width: 1300px;
    }
}

